import React, { useState } from "react"
import * as accordianStyles from "./Accordian.module.scss"

const Accordian = ({ item }) => {
  const [accordianOpen, setAccordianOpen] = useState(false)

  return (
    <li className={accordianStyles.accordianItem}>
      <button
        role="button"
        aria-expanded={accordianOpen ? "true" : "false"}
        className={accordianStyles.accordianButton}
        onClick={() => setAccordianOpen(prev => !prev)}
      >
        <h3>{item.name}</h3>
        {accordianOpen ? (
          <img
            src={
              require("../../../assets/icons/ui/icon-close-accordian-white.svg")
                .default
            }
            alt={`${item.name} - close`}
          />
        ) : (
          <img
            src={
              require("../../../assets/icons/ui/icon-open-accordian-white.svg")
                .default
            }
            alt={`${item.name} - open`}
          />
        )}
      </button>
      {accordianOpen ? (
        <ul className={accordianStyles.recipientList}>
          {item.listItems.map((listItem, index) => (
            <li key={index}>
              <h2>{listItem.title}</h2>
              <p>{listItem.description}</p>
            </li>
          ))}
        </ul>
      ) : null}
    </li>
  )
}

export default Accordian
