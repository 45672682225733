import React from "react"
import InnerHeader from "../../../components/global/page-partials/inner-header-1/InnerHeader"
import Layout from "../../../components/layout"
import Seo from "../../../components/seo"
import * as rcifStyles from "./rcif.module.scss"
import RCIFAccordianData from "../../../components/about-us/rcif/RCIFAccordianData"
import Accordian from "../../../components/about-us/rcif/Accordian"

const Rcif = ({ pageContext, location }) => {
  const pageTitle = "RCIF"

  return (
    <Layout>
      <Seo title={pageTitle} />
      <InnerHeader
        pageTitle={pageTitle}
        pageContext={pageContext}
        location={location}
      />

      <div className={rcifStyles.wrapper}>
        {/* Hero Grid */}
        <section className={rcifStyles.heroGrid}>
          {/* LOGO AND ANCHOR */}
          <div className={rcifStyles.logoAndAnchor}>
            <div className={rcifStyles.rcifLogo}>
              <img
                src={
                  require("../../../assets/images/logos/rcif/rcif-vertical-black.svg")
                    .default
                }
                alt=""
              />
            </div>
            <div>
              <a
                href="https://athens.fcsuite.com/erp/donate/create/fund?funit_id=1034"
                className="CTArcif"
                title="Anchor to How you can Help"
              >
                Give Today
              </a>
            </div>
            <p className={rcifStyles.rcifContact}>
              By Email<br />
              <a href="mailto: rcif@rockybrands.com">
                rcif@rockybrands.com
              </a>
            </p>
            <p className={rcifStyles.rcifContact}>
              By Phone<br />
              <a href="tel:7407539100">740-753-9100</a>
            </p>
            <p className={rcifStyles.rcifContact}>
              By Mail
            </p>
            <div className={rcifStyles.rcifEnvelope}>
              <div>
                <span className={rcifStyles.envelopeLine}></span>
                <span className={rcifStyles.envelopeLineTwo}></span>
                <span className={rcifStyles.envelopeLineThree}></span>
              </div>
              <p>
                Attn: RCIF <br />
                39 E Canal St <br />
                Nelsonville, OH 45764
              </p>
              <div>
                <span className={rcifStyles.envelopeStamp}></span>
              </div>
            </div>
          </div>
          {/* HERO IMAGE */}
          <div className={rcifStyles.heroImage}>
            <picture style={{ display: `none` }}>
              <source
                media="(max-width: 900px)"
                srcSet={
                  require("../../../assets/images/about-us/rcif/rcif-background.jpg")
                    .default
                }
              />
              <source
                media="(max-width: 1300px)"
                srcSet={
                  require("../../../assets/images/about-us/rcif/rcif-background-TB-CROP.jpg")
                    .default
                }
              />
              <img
                src={
                  require("../../../assets/images/about-us/rcif/rcif-background-DT-CROP.jpg")
                    .default
                }
                alt=""
              />
            </picture>
          </div>
        </section>
        {/* END HERO GRID */}
        {/* OUR MISSION */}
        <section className={rcifStyles.ourMission}>
          <div className={rcifStyles.ourMissionContent}>
            <div className={rcifStyles.yearandCTA}>
              <div>
                <div className={rcifStyles.since2009}>
                  <h2>Since 2009</h2>
                </div>
                <div className={rcifStyles.awarded}>
                  <p>
                    RCIF Has awarded over{"  "}
                    {"  "}
                    <span
                      style={{
                        fontWeight: `800`,
                        fontSize: `1.6rem`,
                        paddingLeft: `2px`,
                        paddingRight: `2px`,
                      }}
                    >
                      $900,000
                    </span>
                    {"  "}
                    to area non-profits since May of 2009.{" "}
                  </p>
                  <div>
                    <a
                      href="https://www.grantinterface.com/Process/Apply?urlkey=athens"
                      className="CTArcif"
                      title="2024 Grant Application Form"
                    >
                      Apply for a Grant
                    </a>
                  </div>
                </div>{" "}
              </div>
            </div>
            <div className={rcifStyles.missionStatement}>
              {" "}
              <div className={rcifStyles.content}>
                <h3>Our Mission</h3>
                <p>
                  To provide impactful support to our local communities by
                  awarding grants to build a clean, safe, and healthy community
                  for our neighbors.
                </p>
              </div>
            </div>
          </div>
        </section>
        {/* END OUR MISSION */}

        {/* PAGE CONTENT */}
        <div className={`inner-y-padding ${rcifStyles.pageContentWrapper}`}>
          {/* SECTION STATS */}
          <section className={rcifStyles.sectionStats}>
            <h2>THE STATISTICS</h2>
            <p>
              Nelsonville is located in Athens County, in the heart of Ohio's
              Appalachian region, which represents about 30% of the land in Ohio
              and 32 of its 88 counties. Statistics in this area show a
              population with a high percentage of people living in poverty, low
              per capita income, higher than average high school drop-out rates,
              higher infant mortality rates and out migration from the region.
            </p>
            <p>
              These observations are demonstrated in Athens County, Ohio's
              poorest county.
            </p>
            <ul className="bulleted-list">
              <li>
                In 2022, the poverty rate was 25.5% compared to the state average of 13.6%
              </li>
              <li>
                In 2022, 21.6% of the population was living with severe housing problems
              </li>
              <li>
                In 2022, the high school dropout rate was 9%
              </li>
              <li>
                In 2022, 27.2% of the population is working poor
              </li>
              <li>
                In 2021, 15.6% of the population relied on some kind of financial assistance
              </li>
              <li>
                In 2021, 18.4% of the population relied on the state for medical assistance
              </li>
              <li>
                Most available jobs are low-wage-earning, fast-food, cashier, and retail positions
              </li>
            </ul>
            <br />
            <p>
              *Sources: American Community Survey, DataUSA, Feeding America, and DataOhio
            </p>
            <p>
              Statistics show that only a minor percent of all charitable contributions in Ohio flow t the Appalachian region of the state, despite the fact that this region accounts for almost ten percent of the stat's human need. The Rocky Community Improvement Fund aims to help correct this by funneling money directly in the Nelsonville area, including the surrounding communities of Buchtel, Murray City, Glouster, and Carbon Hill to name a few.
            </p>
          </section>
          {/* END SECTION STATS */}

          {/* SECTION FUNDING */}
          <section>
            <h2>WHERE OUR FUNDING COMES FROM</h2>
            <p>
              The RCIF endowment is built from donors committed to making
              lasting investments in Nelsonville and the surrounding
              communities. Most donations come from Rocky Brands employees and
              through various community fundraising events. Anyone with a vested
              interest in the betterment of our communities is encouraged to
              participate. The endowment is professionally managed by our
              partners at the Athens County Foundation.
            </p>
            <p>
              Since 2013, RCIF has partnered with the Osteopathic Heritage
              Foundation to match charitable contributions made
              by Rocky Brands employees. The mission of the{" "}
              <a
                href="https://osteopathicheritage.org/"
                target="__blank"
                className="rcifLink"
              >
                Osteopathic Heritage Foundation
              </a>{" "}
              is to improve the health and quality of life in the community
              through education, research and service consistent with our
              osteopathic heritage.
            </p>
          </section>
          {/* END SECTION FUNDING */}
          {/* WHERE FUNDING GOES */}
          <section>
            <h2>WHERE OUR FUNDING GOES</h2>
            <p>
              The Rocky Community Improvement Fund aims to positively impact our
              communities by funneling money directly into the area. Projects
              have been funded in Nelsonville, Buchtel, Haydenville, Trimble,
              Murray City, Glouster and Carbon Hill to name a few.
            </p>
            <p>
              The majority of grants awarded are right here in Athens County.
              This means you can see the difference you're making in your own
              community with your donation.
            </p>
          </section>
          {/* END WHERE FUNDING GOES */}
          {/* HOW YOU CAN HELP */}
          <section id="how-you-can-help">
            <h2>HOW YOU CAN HELP</h2>
            <div>
              <a
                href="https://athens.fcsuite.com/erp/donate/create/fund?funit_id=1034"
                className="CTArcif"
                title="Anchor to How you can Help"
              >
                Give Today
              </a>
            </div>
          </section>
          {/* END HOW CAN YOU HELP */}

          {/* SECTION APPLY FOR GRANT */}
          <section
            id="grant-application-forms"
            className={rcifStyles.grantGrid}
            style={{ scrollMarginTop: `7rem` }}
          >
            {/* GRANT COPY */}
            <div className={rcifStyles.grantCopy}>
              <h2>HOW TO APPLY FOR A GRANT</h2>
              <p>
                Grants are awarded twice a year to registered non-profit
                organizations that positively impact our communities. To apply
                please read the current guidelines and submit or mail a
                completed application form.
              </p>
              <p>
                RCIF will be partnering with the Osteopathic Heritage Foundation
                for our Fall Grant distribution. This combined
                effort will allow for even more money to be channeled into the
                local communities. 
              </p>
            </div>
            {/* GRANT FORMS */}
            <div className={rcifStyles.grantApplicationForms}>
              <h3 style={{ marginTop: `2rem` }}>
                2024 Fall Grant Cycle
              </h3>
              <div className={rcifStyles.grantApplicationDeadlines}>
                <div className={rcifStyles.strong}>
                  Application <br />Deadline:
                </div>
                <div className={rcifStyles.dates}>
                  October 13, 2024
                </div>
                <div className={rcifStyles.strong}>
                  Awardee <br />Announcements:
                </div>
                <div className={rcifStyles.dates}>
                  December 2024
                </div>
              </div>
              <div>
                <a
                  href="/docs/rcif/24-Fall-Grant-Guidelines.pdf"
                  title="2024 Fall Grant Guidelines"
                  download
                  >2024 Fall Grant Guidelines</a>
              </div>
              <div>
                <a
                    href="https://www.grantinterface.com/Process/Apply?urlkey=athens"
                    className={`CTArcif ${rcifStyles.CTArcif}`}
                    title="Apply for a Grant"
                  >
                  Click Here to Apply
                </a>
              </div>
            </div>
          </section>
          {/* END SECTION APPLY FOR GRANT */}
          {/* SECTION RECIPIENTS */}
          <section className={rcifStyles.recipients}>
            <h2>
              RCIF AND OSTEOPATHIC HERITAGE FOUNDATION RECIPIENTS
            </h2>
            <div className={rcifStyles.stats}>
              <div>
                <span>571</span>
                <div>Grant Requests Received</div>
              </div>
              <div>
                <span>317</span>
                <div>Grant Requests Funded</div>
              </div>
              <div>
                <div>Total Dollar Amount Awarded</div>
                <span>$924,501</span>
              </div>
            </div>

            <div>
              {/* ACCORDIAN */}
              <ul className={rcifStyles.accordian}>
                {RCIFAccordianData.accordian.map((item, index) => (
                  <Accordian key={index} item={item} />
                ))}
              </ul>
            </div>
          </section>
        </div>
      </div>
    </Layout>
  )
}

export default Rcif

//head element
export const Head = () => (
  <>
    <title data-typesense-field="title">RCIF</title>
    <meta
      name="description"
      data-typesense-field="description"
      content="To provide impactful support to our local communities by awarding grants to build a clean, safe, andhealthy community for our neighbors."
    />
  </>
)
