const RCIFAccordianData = {
  accordian: [
    {
      title: "2024 Spring Recipients",
      name: "2024 Spring Recipients",
      id: "2024-spring-recipients-accordian",
      listItems: [
        {
          title: "Alexander Parent Teacher Organization",
          description: "Inclusive playground equipment project"
        },
        {
          title: "First Presbyterian Church",
          description: "Hygiene products for Presbyterian Food Pantry"
        },
        {
          title: "First United Methodist Church of Nelsonville",
          description: "Feed My Starving Children Initiative"
        },
        {
          title: "Habitat for Humanity of Southeast Ohio",
          description: "Fall 2024 Nelsonville Build"
        },
        {
          title: "Integrated Services for Behavioral Health",
          description: "The Hive of Nelsonville Summer Splash 2024"
        },
        {
          title: "Logan Hocking School District",
          description: "Helping Families Be Healthy Families / Project SAFE."
        },
        {
          title: "Meigs County Farmers Market",
          description: "Donation Station"
        },
        {
          title: "NAMI Athens",
          description: "NAMI Programming Expansion"
        },
        {
          title: "Nelsonville-York City Schools",
          description: "Hygiene Supplies for Classroom"
        },
        {
          title: "The Kathryn McCoy Resource Project",
          description: "Back to School Hygiene Supplies"
        },
        {
          title: "Tomcat Bridgebuilders",
          description: "Youth Drug Prevention Project"
        },
        {
          title: "Truth Food Pantry",
          description: "Freezer/Cooler to Store Food Safe"
        },
        {
          title: "Women for Recovery",
          description: "Therapeutic Programming at Serenity Grove"
        }
      ]
    },
    {
      title: "2023 Fall Recipients",
      name: "2023 Fall Recipients",
      id: "2023-fall-recipients-accordian",
      listItems: [
        {
          title: "Appalachian Center for Economic Networks | Kitchen Toolbox",
          description: "Kitchen Toolbox provides supplies and tools alongside access to produce to ensure the ability to prepare healthy foods."
        },
        {
          title: "Athens City-County Health Department | Accessible Beach Entrance at Stroud's Run State Park Project",
          description: "Funds to assist in the building of an accessible beach entrance at Stroud's Run State Park to promote inclusion/integration for adults and children with disabilities."
        },
        {
          title: "Athens County Department of Job and Family Services | Community Cares Fund's Operation Full Belly",
          description: "Funding for Operation Full Belly aims to reduce food insecurity in Athens County by providing emergency food supplies."
        },
        {
          title: "Athens-Meigs Educational Service Center | Project RISE Internships",
          description: "Project RISE provides paid internships for high school students in Athens, Meigs, and Perry counties for the 2023-2024 school year."
        },
        {
          title: "Hocking County Board of Developmental Disabilities | Hocking County Diaper and Formula Bank",
          description: "Funds to purchase diapers, formula, and wipes to support families and their children."
        },
        {
          title: "Hocking Athens Perry Community Action | Trimble Township Community Center Equipment and Supplies",
          description: "Funding to purchase equipment for an outdoor community and recreational space serving the Trimble community."
        },
        {
          title: "Integrated Services - The Hive of Nelsonville | Parent Support Program",
          description: "Funding for a program to support the parents and caregivers of registered Hive members, focused on strengthening positive parenting practices and building a mutual support network."
        },
        {
          title: "Logan Hocking Local Schools | Helping Families Be Healthy Families",
          description: "Funds to assist in purchasing items for the school food pantry that serves over 150 families."
        },
        {
          title: "Southern Local School District | Feeding the Falcons",
          description: "Funds to purchase a refrigerator and food items for distribution to students."
        },
        {
          title: "Southeast Ohio Hope Center | Tabeling and Meeting Needs",
          description: "Funding to purchase new chairs and tables for meetings at this peer-led, recovery community organization."
        },
        {
          title: "The Athens Village, Inc. | Senior Social Transportation",
          description: "Funding to expand senior transportation to events, churches, and medical offices."
        },
        {
          title: "Trimble Local School District | THS Healthy Snacks",
          description: "Funds to provide healthy snacks throughout the day for students to meet basic needs."
        },
        {
          title: "U4MEME4U | Empowering Our Youth",
          description: "U4MeMe4u empowers, inspires, and nurtures the potential of every child in our community through a comprehensive and inclusive youth day camp experience. Funding will be used for program enrichment, community engagement, and outreach."
        }
      ]
    },
    {
      title: "2023 Spring Recipients",
      name: "2023 Spring Recipients",
      id: "2023-spring-recipients-accordian",
      listItems: [
        {
          title: "AED Replacement Project | Nelsonville Division of Fire",
          description: "Funds to replace outdated AED equipment for EMS services."
        },
        {
          title: "Annual Reading League Conference | Nelsonville-York Elementary School",
          description: "Funds to send school educators to an Annual Reading Conference that aims to provide additional resources to improve strategies to close the gap in reading for students at Nelsonville-York Elementary."
        },
        {
          title: "Boy Scouts Inclusive Playground Project | Appalachian Life Fellowship",
          description: "An Eagle Scout project to add more inclusive play equipment to the Buchtel Village Park."
        },
        {
          title: "Camp Oty'Okwa | Big Brothers Big Sisters of Central Ohio, Inc.",
          description: "Funds to assist with the costs of covering camper fees for 20 Athens and Hocking County youth to attend Camp Oty'Okwa. "
        },
        {
          title: "Dugouts Project | Nelsonville-York Youth League",
          description: "Funds to replace dugouts for home and visiting teams at Nelsonville's Polley Field."
        },
        {
          title: "Harmony & Healthy: Celebrating Culture and Community | Federal Valley Resource Center, Inc.",
          description: "This project is to increase programming, purchase items for the food pantry, and purchase equipment for the resource center that serves Stewart, Ohio."
        },
        {
          title: "Helping Families Be Healthy Families | Logan Hocking Local Schools",
          description: "Funds to assist in purchasing items for the food pantry that serves over 150 families."
        },
        {
          title: "iBelieve Youth Leadership Workshops | The iBelieve Foundation",
          description: "Funds to assist with fees needed to send 10 students from local schools to leadership workshops located on college campuses."
        },
        {
          title: "Instrument Repair Project | The Nelsonville-York Band",
          description: "Funds to repair broken instruments used by students in the Nelsonville-York Band."
        },
        {
          title: "Library Outreach Collections | Athens County Public Libraries ",
          description: "This project aims to get resources into the hands of those that need them and may have trouble accessing or using a branch library."
        },
        {
          title: "LSS Food Pantries in Athens County | Lutheran Social Services of Central Ohio",
          description: "Funds to assist in purchasing items for the LSS Food Pantries in Athens County."
        },
        {
          title: "Meditative Yoga & Writing | Women for Recovery",
          description: "This project provides meditative yoga and writing sessions to support residents dedicated to transitioning back into independent sober living within the community."
        },
        {
          title: "Neighbor Loaves & Meals: Addressing Food Insecurity with Local Ingredients | Rural Action, Inc.",
          description: "Funds to assist in the purchasing of local nutritious and high-quality food from local bakers and chefs to be provided to individuals experiencing food insecurity."
        },
        {
          title: "Park Improvement Project | Village of Murray City",
          description: "Funds to improve Murray City Park, including new basketball hoops and play equipment."
        },
        {
          title: "Student Scholarship & Financial Assistance Fund | Hocking College Foundation, Inc.",
          description: "Funds to assist with tuition and class-related items, as well as emergency items needed such as food and medical care."
        },
        {
          title: "Summer Splash 2023 | Integrated Services - The Hive of Nelsonville",
          description: "Funds to assist with costs associated to send youth to the Nelsonville Aquatic Center for Summer 2023."
        },
      ],
    },
    {
      title: "2022 Fall Recipients",
      name: "2022 Fall Recipients",
      id: "2022-fall-recipients-accordian",
      listItems: [
        {
          title: "Children's Hunger Alliance",
          description: ""
        },
        {
          title: "Athens County Department of Job & Family Services - Operation Full Belly",
          description: ""
        },
        {
          title: "Trimble Local Textbooks & Supplies Foundation",
          description: ""
        },
        {
          title: "My Sister's Place",
          description: ""
        },
        {
          title: "Logan Hocking Schools",
          description: ""
        },
        {
          title: "Nelsonville-York Elementary School",
          description: ""
        },
        {
          title: "Big Brothers Big Sisters of Central Ohio",
          description: ""
        },
        {
          title: "Stuart's Opera House",
          description: ""
        },
        {
          title: "Blessings in a Backpack",
          description: ""
        },
        {
          title: "Ohio University International Food Pantry",
          description: ""
        },
        {
          title: "Marietta College Nonprofits LEAD",
          description: ""
        },
      ],
    },
    {
      title: "2022 Spring Recipients",
      name: "2022 Spring Recipients",
      id: "2022-spring-recipients-accordian",
      listItems: [
        {
          title: "Athens County Community Food Initiatives",
          description: ""
        },
        {
          title: "Big Brothers Big Sisters of Central Ohio",
          description: ""
        },
        {
          title: "Village of New Straitsville - Delyn Center",
          description: ""
        },
        {
          title: "Ohio University Diabetes Institute - CAT Camp",
          description: ""
        },
        {
          title: "Habitat for Humanity",
          description: ""
        },
        {
          title: "Logan Hocking Schools",
          description: ""
        },
        {
          title: "Lutheran Social Services Food Pantries",
          description: ""
        },
        {
          title: "Nelsonville Presbyterian Church Pre-K",
          description: ""
        },
        {
          title: "Project RISE",
          description: ""
        },
        {
          title: "The Nelsonville Hive",
          description: ""
        },
      ],
    },
  ],
}

export default RCIFAccordianData
